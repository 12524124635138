import { useEffect, useContext } from 'react'

import fileDownload from 'js-file-download'

import { SumSubBanner } from './SumSubBanner'
import { TransactionsTable } from './TransactionsTable'
import { TransactionsFilter } from '../transactions/TransactionsFilter'
import { BalanceStatementModal } from '../transactions/BalanceStatement'
import { TransactionsExportModal } from '../transactions/TransactionsExportModal'
import { Button, ErrorDisplay, Pagination } from 'mmfintech-portal-commons'
import { DownloadIcon, NoTransactionsIcon } from '../../icons'
import { DashboardStyled, TransactionsContainer, TransactionsExportButtons } from './Dashboard.styled'

import settings from '../../settings'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import {
  configuration,
  endpoints,
  useFileDownloader,
  useMerchantAccounts,
  useTransactionsFilterQry
} from 'mmfintech-backend-api'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

export const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const { activeAccounts } = useMerchantAccounts()

  const { download, fetching: downloadFetching, reset: resetDownloader } = useFileDownloader({})
  const filter = useTransactionsFilterQry({
    enableCustomerEmail: false,
    // accountId,
    cookieDomain: configuration.isLocal() ? null : settings.cookieDomain
    // defaultVisible: ['amount', 'currency', 'foreignId', 'reference', 'status'],
    // isAutoReload: false
  })

  const handleBalanceStatementClick = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: (
        <BalanceStatementModal
          account={activeAccounts.find(
            (account: AccountBalanceResponse) => account.id === filter.values.filterValues?.accountId
          )}
          accounts={activeAccounts}
          dateFrom={filter.values.get('from')}
          dateTo={filter.values.get('to')}
        />
      )
    })
  }

  const handleExportClick = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: (
        <TransactionsExportModal
          account={activeAccounts.find(
            (account: AccountBalanceResponse) => account.id === filter.values.filterValues?.accountId
          )}
          accounts={activeAccounts}
          dateFrom={filter.values.get('from')}
          dateTo={filter.values.get('to')}
        />
      )
    })
  }

  const handleReportCSV = () => {
    if (!downloadFetching) {
      void download({
        url: endpoints.transactions.getReportCSV(),
        data: filter.prepareFilter(),
        onSuccess: (response, filename) => {
          fileDownload(response, filename || 'transactions-report.csv', 'text/csv')
        }
      })
    }
  }

  useEffect(() => {
    return () => {
      resetDownloader()
    }
  }, [])

  return (
    <DashboardStyled>
      <SumSubBanner />
      <TransactionsFilter filter={filter} />

      {filter.transactionsFetching ? null : filter.transactionsError ? (
        <ErrorDisplay error={filter.transactionsError} />
      ) : isValidArray(filter.transactions) ? (
        <TransactionsContainer>
          <TransactionsTable transactions={filter.transactions} />
          <div className='lower-section'>
            <span />
            <Pagination className='transactions-pagination' {...filter.pagination.register()} />
            <TransactionsExportButtons>
              <Button
                color='round-secondary'
                icon={<DownloadIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_BALANCE_STATEMENT', 'Balance Statement')}
                onClick={handleBalanceStatementClick}
                data-test='button-balance-statement'
              />
              <Button
                color='round-secondary'
                icon={<DownloadIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_MOVEMENT_CSV', 'Account Movement CSV')}
                onClick={handleExportClick}
                data-test='button-account-movement-csv'
              />
              <Button
                color='round-secondary'
                icon={<DownloadIcon />}
                text={tr('FRONTEND.DASHBOARD.BUTTON_EXPORT_TRANSACTIONS', 'Export Transactions CSV')}
                onClick={handleReportCSV}
                data-test='button-transactions-csv'
              />
            </TransactionsExportButtons>
          </div>
        </TransactionsContainer>
      ) : (
        <div className='no-elements'>
          <NoTransactionsIcon />
          <div>{tr('FRONTEND.TRANSACTIONS.NONE_FOUND', 'No transactions found')}</div>
        </div>
      )}
    </DashboardStyled>
  )
}
