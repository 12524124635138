import styled from 'styled-components'

export const AccessDeniedContainer = styled.div`
  grid-area: main-content;

  background: white;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  max-height: 23rem;
  max-width: 35rem;

  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    max-width: 50rem;
  }
`

export const AccessDeniedContent = styled.div`
  margin: 0 auto;
  text-align: center;

  padding: 0 3rem 3rem;

  .button {
    max-width: 40rem;
    margin-top: 2rem;

    font-size: 1.8rem;
  }
`

export const AccessDeniedTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 400;
`

export const AccessDeniedDescription = styled.h2`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
`
