import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { enableMapSet, enableES5 } from 'immer'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'mmfintech-portal-commons/dist/main.css'
import './index.css'

import App from './App'
import { store } from './store'

enableMapSet()
enableES5()

const root = ReactDOM.createRoot(document.getElementById('tomopay-app') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
