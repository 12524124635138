import React from 'react'

import { useLoginQry } from 'mmfintech-backend-api'

import { LanguageMenu, LoginForm, TomopayLogo } from '../../../components'
import { LoginContainer, LoginHeader, LoginWrapper } from './Login.styled'

export const Login = () => {
  const { formValues, login, loginError, loginFetching, resetLogin } = useLoginQry()

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void login()
  }

  return (
    <LoginWrapper>
      <LoginHeader>
        <TomopayLogo autoWidth />
        <div className='language-selection'>
          <LanguageMenu />
        </div>
      </LoginHeader>
      <LoginContainer>
        <LoginForm
          formValues={formValues}
          handleSubmit={handleSubmit}
          loading={loginFetching}
          error={loginError}
          onReset={resetLogin}
        />
        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}
